@import url(https://fonts.googleapis.com/css?family=Lato|Parisienne);

body {
 	margin: 0 auto;
  padding: 0;
  font-family: sans-serif;
  font-weight: 80;
 	letter-spacing: 0.07px;
 	line-height: 140%;
}

fullScreen {
	width: 100%;
	float: left;
}