@import url(https://fonts.googleapis.com/css?family=Lato|Parisienne);
body {
 	margin: 0 auto;
  padding: 0;
  font-family: sans-serif;
  font-weight: 80;
 	letter-spacing: 0.07px;
 	line-height: 140%;
}

fullScreen {
	width: 100%;
	float: left;
}

.pad-top-10 {
  padding-top: 10px;
}

.pad-top-30 {
  padding-top: 30px;
}

.pad-top-60 {
  padding-top: 60px;
}

a {
  color: #222222;
}

ul {
padding:0;
margin:0;
}

ul li {
  text-align: left;
  padding-left:8px;
  margin-bottom:6px;
}

.dark-purple {
  color: #9960D0;
}

.dark-purple a {
  color: #7760D0;
}

.App {
  text-align: left;
}

#App-hamburger {
  height: 35px;
  width: 35px;
  background-color: #FFFFFF;
  display: block;
  position: fixed;
  z-index: 999999999999;
  box-shadow: 0px 0px 1px 0px #666666;
  margin-top: 20px;
  border-radius: 8px;

  transform: translateX(20px);
  transition: transform .2s linear;
}

#App-hamburger:hover {
  cursor: pointer;
}

#App-hamburger.slideOutBurger {
  transform: translateX(165px);
}

#App-hamburger.slideInBurger {
  transform: translateX(20px);
}

#App-side-menu {
  width: 215px;
  height: 100%;
  position: fixed;
  display: block;
  z-index: 0;
  box-shadow: 0px 0px 3px 0px #666666;
  opacity: 0;

  transform: translateX(-145px);
  transition: transform .2s linear;
}

#App-side-menu.slideIn {
  transform: translateX(0px);

  -webkit-animation-name: Enter-hamburger;  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.5s;  /* Safari 4.0 - 8.0 */    
  -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
  animation-name: Enter-hamburger;
  animation-duration: 0.5s;    
  animation-fill-mode: forwards;
}

#App-side-menu.slideOut {
  transform: translateX(-145px);

  -webkit-animation-name: Exit-hamburger;  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.5s;  /* Safari 4.0 - 8.0 */    
  -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
  animation-name: Exit-hamburger;
  animation-duration: 0.5s;    
  animation-fill-mode: forwards;
}

h1 {
  text-align: center;
  width: 100%;
  font-weight: 200;
  line-height: 1.1em;
}

h2 {
  text-align: center;
  width: 100%;
  font-weight: 200;
}

h3 {
  font-weight: 200;
}

h6 {
  font-weight: 200;
  color: #333333;
}

i {
  padding: 10px;
  color: #666;
  display: block;
}

.icon-text {
  font-family: 'Parisienne';
  font-size: 45px;
  color: #FFFFFF;
  text-align: center;
}

.text-left {

  text-align: left;
}

.image {
  float: left;
}

.center {
  margin: 0 auto;
  width: 100%;
}

.center-text {
  text-align: center;
}

.list-item-separator {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 20px;
  padding-top: 15px;

  padding-left: 15px;
  padding-right: 15px;
}

.image-rounded {
  border-radius: 9px;
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.Header-list {
  padding-top: 78px;
  color: #2C2C2C;
  text-align: center;
}

.Header-list li {
  height: 60px;
  display: block;
  border-top: 1px solid #e8e8e8;
  text-align: center;
}

.class-table table, th, td {
  border: 1px solid #C1C8E4;
  border-collapse: collapse;
  margin: 5px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
}

.Header-list a {
  padding-top: 20px;
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.Header-list a:hover, li:hover {
  cursor: pointer;
}

.App-logo {
  /*animation: App-logo-spin infinite 20s linear;*/
  height: 80px;
}

.App-header {
  background-color: #8860D0;
  color: white;
  text-align: center;
}

.App-intro {
  font-size: large;
}

.App-loading {
  -webkit-animation: App-logo-spin infinite 2s linear;
          animation: App-logo-spin infinite 2s linear;
  height: 80px;
}

.form-label {
  padding-top: 20px;
  width: 100%;
  display: block;
}

.form-element {
  border-radius: 7px;
  border-width: 0.5px;
  height: 28px;
  width: 90%;
  max-width: 400px;
  margin: 10px;
}

.form-content {
  border-radius: 7px;
  border-width: 0.5px;
  width: 100%;
  max-width: 400px;
}

.form-content-admin{
  border-radius: 7px;
  border-width: 0.5px;
  width: 100%;
}

.readonly {
  background-color: #F3F3F3;
}

.btn {
  border-radius: 7px;
  border-width: 0.5px;
  background-color: #4BAF50;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;


  padding-left: 5px;
  padding-right: 5px;
  margin-left: 5px;
  margin-right: 5px;
}

.submit-btn {
  border-radius: 7px;
  border-width: 0.5px;
  height: 28px;
  width: 50%;
  max-width: 200px;
}

.class-button {
  background-color: #84CEEB; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  margin: 10px 5px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  border-radius: 7px;
}

.table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table td th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4CAC50;
  color: white;
}

.table tr:hover { 
  background-color: #ddd;
}

.jumbotron {
  background: #C1C8E4;
  border-radius: 12px;
  padding: 10px;
  margin: 10px;
}

.jumbotron-hover:hover {
  cursor: pointer;
}

.jumbotron-text {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}


.floatLeft {
  float: left;
  width: 100%;
}

.fullWidth {
  width: 100%;
  margin: 0;
  text-align: center;
}


.centerPadded {
  width: 100%;
  padding: 30px 200px;
  margin: 0 auto;
  text-align: center;
}

.Enter-screen {
  width: 100%;
  position: relative;
  -webkit-animation-name: Enter-screen-inup;  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.5s;  /* Safari 4.0 - 8.0 */    
  -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
  animation-name: Enter-screen-inup;
  animation-duration: 0.5s;    
  animation-fill-mode: forwards;
  
}

@-webkit-keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*
    ENTRANCE OF ITEMS

*/

/* Safari 4.0 - 8.0 */
@-webkit-keyframes Enter-screen-inup {
    from {opacity: 0.0; top: 20px;}
    to {opacity: 1.0; top: 0px; }
}

@keyframes Enter-screen-inup {
    from {opacity: 0.0; top: 20px;}
    to {opacity: 1.0; top: 0px;}
}

/*
    ENTRANCE OF SIDE MENU

*/

/* Safari 4.0 - 8.0 */
@-webkit-keyframes Enter-hamburger {
    from { z-index: -99999; opacity: 0; }
    to { z-index: 99999999; background-color: #FFFFFF; opacity: 0.9; }
}

@keyframes Enter-hamburger {
    from { z-index: -99999; opacity: 0; }
    to { z-index: 99999999; background-color: #FFFFFF; opacity: 0.9; }
}

@-webkit-keyframes Exit-hamburger {
    from { z-index: 99999999; background-color: #FFFFFF; opacity: 0.9; }
    to { z-index: -99999; background-color: none; opacity: 0; }
}

@keyframes Exit-hamburger {
    from { z-index: 99999999; background-color: #FFFFFF; opacity: 0.9; }
    to { z-index: -99999; background-color: none; opacity: 0; }
}

